import textResource from '../../../../utils/text-resources/digifinans/NO/messages';

const EMPLOYMENT_TYPES = [
  {
    label: textResource.employmentTypePermanentPrivate,
    value: 'untilFurtherNoticePrivate',
  },
  {
    label: textResource.employmentTypePermanentPublic,
    value: 'untilFurtherNoticePublic',
  },
  {
    label: textResource.employmentTypeByTheHour,
    value: 'byTheHour',
  },
  {
    label: textResource.employmentTypeSelfEmployed,
    value: 'selfEmployed',
  },
  {
    label: textResource.employmentTypeRetired,
    value: 'retired',
  },
  {
    label: textResource.employmentTypeDisabilityPension,
    value: 'disabilityPension',
  },
  {
    label: textResource.employmentTypeRehabilitation,
    value: 'rehabilitation',
  },
  {
    label: textResource.employmentTypeUnemployed,
    value: 'unemployed',
  },
];

export default EMPLOYMENT_TYPES;
