import textResource from '../../../../utils/text-resources/digifinans/NO/messages';

const LOAN_PURPOSES = [
  {
    label: textResource.loanPurposeVehicles,
    value: 'vehicle',
  },
  {
    label: textResource.loanPurposeRenovation,
    value: 'renovation',
  },
  {
    label: textResource.loanPurposeHome,
    value: 'home',
  },
  {
    label: textResource.loanPurposeTravel,
    value: 'travel',
  },
  {
    label: textResource.loanPurposeBusiness,
    value: 'business',
  },
  {
    label: textResource.loanPurposeEverydayExpenses,
    value: 'everydayExpenses',
  },
  {
    label: textResource.loanPurposeOther,
    value: 'other',
  },
];
const NEW_LOAN_FORM_PURPOSES = [
  ...LOAN_PURPOSES,
  {
    label: textResource.loanPurposeConsolidateLoans,
    value: 'consolidateLoans',
  },
];

export { LOAN_PURPOSES, NEW_LOAN_FORM_PURPOSES };

