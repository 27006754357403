// external
import { region } from '../../localizations/current-locale';
import { dynamicBrand } from '../../utils/brand';
// internal
import Config from '../../utils/config';
import { valid, invalid } from '../../utils/validators/resolutions';
import stripDelimiter from '../../utils/strip-delimiter-from-currency';

// eslint-disable-next-line import/no-dynamic-require
let messages;
import(`../../utils/text-resources/${dynamicBrand()}/${region}/messages`).then((m) => {
  messages = m.default;
});

const validateRentalIncome = (input = '') => {
  const cleanedInput = stripDelimiter(input);
  const config = Config.getValues();

  if (!/\S+/.test(cleanedInput || '')) {
    return invalid(messages.rentalIncomeRequired);
  }

  if (!/^(-?\d+)$/.test(cleanedInput)) {
    return invalid(messages.rentalIncomeOnlyNumbers);
  }

  const value = parseInt(cleanedInput, 10);

  const maxRentalIncome = config.RESTRICTIONS.MAX_RENTAL_INCOME;

  if (value > maxRentalIncome) {
    return invalid(messages.rentalIncomeTooHigh, { maxRentalIncome });
  }

  if (value < 0) {
    return invalid(messages.salaryMustBePositive);
  }

  return valid();
};

export default validateRentalIncome;
