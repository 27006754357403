import format from 'date-fns/format';

import stripDelimiter from '../../utils/strip-delimiter-from-currency';
import { normalizePhoneNumber } from '../../utils/normalize-phone-number';
import submitCurrentLoans from './currentLoans';

import {
  isEmployed,
  hasSpouse,
} from './applicantFunctions';

export const submitApplicant = (applicant, visibleFields) => ({
  email: applicant.email || null,
  phoneNumber: normalizePhoneNumber(applicant.phoneNumber) || null,
  nationalId: applicant.nationalId || null,
  dependants: String(parseInt(applicant.dependants, 10)) || 0,
  civilStatus: applicant.civilStatus || null,
  monthlySalary: Math.round(parseInt(stripDelimiter(applicant.annualIncomeBeforeTax), 10) / 12) || null, // eslint-disable-line
  monthlyNetSalary: parseInt(stripDelimiter(applicant.monthlyIncomeAfterTax), 10)|| null, // eslint-disable-line
  otherIncomeSource: applicant.otherIncome ? (applicant.otherIncomeSource || null) : null,
  rentalMonthlyIncomeBeforeTax: applicant.rentalMonthlyIncomeBeforeTax
    ? (parseInt(stripDelimiter(applicant.rentalMonthlyIncomeBeforeTax), 10) || null) : null, // eslint-disable-line
  otherMonthlyIncomeAfterTax: applicant.otherIncome
    ? parseInt(stripDelimiter(applicant.otherMonthlyIncomeAfterTax), 10) || null : null,
  otherMonthlyIncomeBeforeTax: applicant.otherIncome
    ? parseInt(stripDelimiter(applicant.otherMonthlyIncomeBeforeTax), 10) || null : null,
  spouseMonthlySalary: hasSpouse(applicant) && applicant.spouseAnnualIncomeBeforeTax
    ? Math.round(parseInt(stripDelimiter(applicant.spouseAnnualIncomeBeforeTax), 10) / 12) : null,
  homeType: applicant.homeType || null,
  homeCost: String(parseInt(stripDelimiter(applicant.homeCost), 10)) || null,
  employmentType: applicant.employmentType || null,
  employerName: visibleFields.applicant.employerName
    ? ((isEmployed(applicant) && applicant.employerName) || null) : null,
  employmentSince: applicant.employmentSince
    ? format(
      new Date(applicant.employmentSince, (visibleFields.applicant.employmentSinceMonth
        ? (applicant.employmentSinceMonth || 0) : 0)),
      'yyyy-MM-dd\'T\'HH:mm:ss',
    )
    : null,
  employmentTo: visibleFields.applicant.employmentTo && applicant.employmentTo
    ? format(
      new Date(applicant.employmentTo, (applicant.employmentToMonth || 0)),
      'yyyy-MM-dd\'T\'HH:mm:ss',
    )
    : null,
  educationLevel: applicant.educationLevel || null,
  moveInDate: applicant.moveInDate
    ? format(
      new Date(applicant.moveInDate, 0),
      'yyyy-MM-dd\'T\'HH:mm:ss',
    ) : null,
  currentLoans: submitCurrentLoans(applicant),
});

export const submitCoApplicant = (applicant, visibleFields) => ({
  email: applicant.email || null,
  phoneNumber: normalizePhoneNumber(applicant.phoneNumber) || null,
  nationalId: applicant.nationalId || null,
  dependants: String(parseInt(applicant.dependants, 10)) || 0,
  civilStatus: applicant.civilStatus || null,
  monthlySalary: Math.round(parseInt(stripDelimiter(applicant.annualIncomeBeforeTax), 10) / 12) || null, // eslint-disable-line
  monthlyNetSalary: parseInt(stripDelimiter(applicant.monthlyIncomeAfterTax), 10)|| null, // eslint-disable-line
  otherIncomeSource: applicant.otherIncome ? (applicant.otherIncomeSource || null) : null,
  rentalMonthlyIncomeBeforeTax: applicant.rentalMonthlyIncomeBeforeTax
    ? (parseInt(stripDelimiter(applicant.rentalMonthlyIncomeBeforeTax), 10) || null) : null, // eslint-disable-line
  otherMonthlyIncomeAfterTax: applicant.otherIncome
    ? parseInt(stripDelimiter(applicant.otherMonthlyIncomeAfterTax), 10) || null : null,
  otherMonthlyIncomeBeforeTax: applicant.otherIncome
    ? parseInt(stripDelimiter(applicant.otherMonthlyIncomeBeforeTax), 10) || null : null,
  spouseMonthlySalary: hasSpouse(applicant) && applicant.spouseAnnualIncomeBeforeTax
    ? Math.round(parseInt(stripDelimiter(applicant.spouseAnnualIncomeBeforeTax), 10) / 12) : null,
  homeType: applicant.homeType || null,
  homeCost: String(parseInt(stripDelimiter(applicant.homeCost), 10)) || null,
  employmentType: applicant.employmentType || null,
  employerName: visibleFields.coApplicant.employerName
    ? ((isEmployed(applicant) && applicant.employerName) || null) : null,
  employmentSince: applicant.employmentSince
    ? format(
      new Date(applicant.employmentSince, (visibleFields.coApplicant.employmentSinceMonth
        ? (applicant.employmentSinceMonth || 0) : 0)),
      'yyyy-MM-dd\'T\'HH:mm:ss',
    )
    : null,
  employmentTo: visibleFields.coApplicant.employmentTo && applicant.employmentTo
    ? format(
      new Date(applicant.employmentTo, (applicant.employmentToMonth || 0)),
      'yyyy-MM-dd\'T\'HH:mm:ss',
    )
    : null,
  educationLevel: applicant.educationLevel || null,
  moveInDate: applicant.moveInDate
    ? format(
      new Date(applicant.moveInDate, 0),
      'yyyy-MM-dd\'T\'HH:mm:ss',
    ) : null,
  currentLoans: submitCurrentLoans(applicant),
});
