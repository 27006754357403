export default {
  gridTemplate: ' 1fr 1fr 1fr 1fr 1fr 1fr',
  gridDefaultStart: '1',
  gridDefaultEnd: '2',
  gridColumnGap: '0.5rem',
  gridMarginRight: '1rem',
  formTextColor: 'var(--digi-blue-700)',
  loanCalculatorPadding: '1.5rem',
  firstBottomButtonStart: '4',
  firstBottomButtonEnd: '5',
  secondBottomButtonStart: '5',
  secondBottomButtonEnd: '7',
  submitButtonStart: '5',
  submitButtonEnd: '7',
};
