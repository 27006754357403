import stripDelimiter from '../../utils/strip-delimiter-from-currency';

export const loanTypeMapper = (type) => {
  /*
    Valid types in backend
    CAR: 0,
    CREDIT_CARD: 1,
    STUDENT: 2,
    CONSUMER: 3,
    MORTGAGE: 4,
    INSTALLMENT: 5,
    OTHER: 6,
  */
  switch (type) {
    case 'houseLoan':
      return 'mortgage';
    case 'studentLoan':
      return 'student';
    default:
      return type;
  }
};

const submitCurrentLoans = (applicant) => {
  const loans = Object.keys(applicant.currentLoans);
  const current = applicant.currentLoans;
  const output = [];

  for (let i = 0; i < loans.length; i += 1) {
    const loanType = loans[i];
    const loan = current[loanType];
    if (loan.amount && loan.monthlyCost) {
      const temp = {
        amount: parseInt(stripDelimiter(loan.amount), 10),
        monthlyPayment: parseInt(stripDelimiter(loan.monthlyCost), 10) || null, // eslint-disable-line
        type: loanTypeMapper(loanType),
        transfer: false,
      };
      if (loanType === 'houseLoan' && applicant.houseLoanPart) {
        temp.share = Number(applicant.houseLoanPart);
      }

      if (loanType === 'carLoan' && applicant.carLoanPart) {
        temp.share = Number(applicant.carLoanPart);
      }

      if (
        (applicant.hasCarLoan && loanType === 'car')
        || (loanType === 'houseLoan' && (applicant.homeType === 'villa' || applicant.homeType === 'condominium' || applicant.homeType === 'ownedApartment'))
        || (applicant.hasStudentLoan && loanType === 'studentLoan')
      ) {
        output.push(temp);
      }
    }
  }
  return output;
};

export default submitCurrentLoans;
