// external
import { region } from '../../localizations/current-locale';
import { dynamicBrand } from '../../utils/brand';
import Config from '../../utils/config';

// internal
import { valid, invalid } from '../../utils/validators/resolutions';
import stripDelimiter from '../../utils/strip-delimiter-from-currency';
import utilsMessages from '../../utils/messages';

// eslint-disable-next-line import/no-dynamic-require
let messages;
import(`../../utils/text-resources/${dynamicBrand()}/${region}/messages`).then((m) => {
  messages = m.default;
});

export const validateCurrentLoans = (input = '', ...props) => {
  const cleanedInput = stripDelimiter(input);
  const config = Config.getValues();

  let maxLimit;

  switch (props[2]) {
    case 'applicant.currentLoans.houseLoan.amount':
      maxLimit = config.RESTRICTIONS.MAX_HOUSE_LOAN_AMOUNT;
      break;
    case 'coApplicant.currentLoans.houseLoan.amount':
      maxLimit = config.RESTRICTIONS.MAX_HOUSE_LOAN_AMOUNT;
      break;
    case 'applicant.currentLoans.car.amount':
      maxLimit = config.RESTRICTIONS.MAX_CAR_LOAN_AMOUNT;
      break;
    case 'coApplicant.currentLoans.car.amount':
      maxLimit = config.RESTRICTIONS.MAX_CAR_LOAN_AMOUNT;
      break;
    case 'applicant.currentLoans.studentLoan.amount':
      maxLimit = config.RESTRICTIONS.MAX_STUDENT_LOAN_AMOUNT;
      break;
    case 'coApplicant.currentLoans.studentLoan.amount':
      maxLimit = config.RESTRICTIONS.MAX_STUDENT_LOAN_AMOUNT;
      break;
    default:
      maxLimit = 10000000;
  }

  if (!/^(-?\d+)$/.test(cleanedInput)) {
    return invalid(utilsMessages.genericOnlyNumbers);
  }

  if (cleanedInput > maxLimit) {
    return invalid(messages.tooHighCurrentLoan, { maxLimit });
  }

  return valid();
};

export const validateMonthlyCostCurrentLoans = (input = '', ...props) => {
  const cleanedInput = stripDelimiter(input);
  const config = Config.getValues();

  let maxLimit;

  switch (props[2]) {
    case 'applicant.currentLoans.houseLoan.monthlyCost':
      maxLimit = config.RESTRICTIONS.MAX_HOUSE_LOAN_MONTHLY_COST;
      break;
    case 'coApplicant.currentLoans.houseLoan.monthlyCost':
      maxLimit = config.RESTRICTIONS.MAX_HOUSE_LOAN_MONTHLY_COST;
      break;
    case 'applicant.currentLoans.car.monthlyCost':
      maxLimit = config.RESTRICTIONS.MAX_CAR_LOAN_MONTHLY_COST;
      break;
    case 'coApplicant.currentLoans.car.monthlyCost':
      maxLimit = config.RESTRICTIONS.MAX_CAR_LOAN_MONTHLY_COST;
      break;
    case 'applicant.currentLoans.studentLoan.monthlyCost':
      maxLimit = config.RESTRICTIONS.MAX_STUDENT_LOAN_MONTHLY_COST;
      break;
    case 'coApplicant.currentLoans.studentLoan.monthlyCost':
      maxLimit = config.RESTRICTIONS.MAX_STUDENT_LOAN_MONTHLY_COST;
      break;
    default:
      maxLimit = 100000;
  }

  if (!/^(-?\d+)$/.test(cleanedInput)) {
    return invalid(utilsMessages.genericOnlyNumbers);
  }

  if (cleanedInput > maxLimit) {
    return invalid(messages.tooHighCurrentLoanMonthlyCost, { maxLimit });
  }

  return valid();
};
