// external
import Config from '../../utils/config';
import { region } from '../../localizations/current-locale';
import { dynamicBrand } from '../../utils/brand';

// internal
import { valid, invalid } from '../../utils/validators/resolutions';
import stripDelimiter from '../../utils/strip-delimiter-from-currency';

// eslint-disable-next-line import/no-dynamic-require
let messages;
import(`../../utils/text-resources/${dynamicBrand()}/${region}/messages`).then((m) => {
  messages = m.default;
});

export const validateAnnualSalaryBeforeTax = (input = '', ...props) => {
  const cleanedInput = stripDelimiter(input);
  const config = Config.getValues();

  if (!/\S+/.test(cleanedInput || '')) {
    return (props[2] === 'applicant.annualIncomeBeforeTax'
      ? invalid(messages?.annualSalaryRequired)
      : invalid(messages?.coApplicantAnnualSalaryRequired));
  }

  if (!/^(-?\d+)$/.test(cleanedInput)) {
    return (props[2] === 'applicant.annualIncomeBeforeTax'
      ? invalid(messages?.monthlySalaryOnlynumbers)
      : invalid(messages?.coApplicantMonthlySalaryOnlynumbers));
  }

  const value = parseInt(cleanedInput, 10);

  const minAnnualSalary = config.RESTRICTIONS.MIN_ANNUAL_SALARY;
  const maxAnnualSalary = config.RESTRICTIONS.MAX_ANNUAL_SALARY;

  if (value < minAnnualSalary) {
    return invalid(messages.annualSalaryTooLow, { minAnnualSalary });
  }

  if (value > maxAnnualSalary) {
    return invalid(messages.annualSalaryTooHigh, { maxAnnualSalary });
  }

  if (value < 0) {
    return invalid(messages.salaryMustBePositive);
  }

  return valid();
};

export const spouseAnnualIncomeBeforeTaxValidator = (input = '') => {
  const cleanedInput = stripDelimiter(input);
  const config = Config.getValues();

  if (!/\S+/.test(cleanedInput || '')) {
    return invalid(messages.spouseAnnualSalaryRequired);
  }

  if (!/^(-?\d+)$/.test(cleanedInput)) {
    return invalid(messages.spouseMonthlySalaryOnlynumbers);
  }

  const value = parseInt(cleanedInput, 10);

  const minAnnualSalary = 0;
  const maxAnnualSalary = config.RESTRICTIONS.MAX_ANNUAL_SALARY;

  if (value < minAnnualSalary) {
    return invalid(messages.annualSalaryTooLow, { minAnnualSalary });
  }

  if (value > maxAnnualSalary) {
    return invalid(messages.annualSalaryTooHigh, { maxAnnualSalary });
  }

  if (value < 0) {
    return invalid(messages.salaryMustBePositive);
  }
  return valid();
};
