export const isEmployed = (applicant) => [
  'untilFurtherNoticePrivate',
  'untilFurtherNoticePublic',
  'permanent',
  'partTime',
  'temporary',
  'byTheHour',
  'selfEmployed',
  'employedAtOwnCompany',
  'project',
  'other',
].includes(applicant.employmentType);

export const isRetired = (applicant) => applicant.employmentType === 'retired' || applicant.employmentType === 'disabilityPension';

export const isTempEmployed = (applicant) => applicant.employmentType === 'temporary';

export const isSelfEmployed = (applicant) => applicant.employmentType === 'selfEmployed';

export const hasSpouse = (applicant) => [
  'partner',
  'married',
].includes(applicant.civilStatus);
