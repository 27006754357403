import textResource from '../../../../utils/text-resources/digifinans/NO/messages';

const HOME_TYPES = [
  {
    label: textResource.homeTypeRentingApartment,
    value: 'rentedApartment',
  },
  {
    label: textResource.homeTypeVilla,
    value: 'villa',
  },
  {
    label: textResource.homeTypeOwnApartment,
    value: 'ownedApartment',
  },
  {
    label: textResource.homeTypeCondominium,
    value: 'condominium',
  },
  {
    label: textResource.homeTypeParents,
    value: 'parents',
  },
];

export default HOME_TYPES;
