const LOAN_RESTRICTIONS = {
  MIN_AGE: 18,
  MAX_AGE: 99,
  LOAN_MIN_YEARS: 1,
  LOAN_MAX_YEARS: 15,
  MIN_SALARY: 7000,
  MAX_SALARY: 125000,
  MIN_ANNUAL_SALARY: 120000,
  MAX_ANNUAL_SALARY: 3000000,
  MIN_HOME_COST: 0,
  MAX_HOME_COST: 20000,
  MIN_FEES_COST: 0,
  MAX_FEES_COST: 1000,
  MAX_RENTAL_INCOME: 150000,
  MAX_HOUSE_LOAN_AMOUNT: 25000000,
  MAX_HOUSE_LOAN_MONTHLY_COST: 99000,
  MAX_CAR_LOAN_AMOUNT: 10000000,
  MAX_CAR_LOAN_MONTHLY_COST: 20000,
  MAX_STUDENT_LOAN_AMOUNT: 10000000,
  MAX_STUDENT_LOAN_MONTHLY_COST: 20000,
  MAX_OTHER_INCOME: 50000,
};

export default LOAN_RESTRICTIONS;
