// external
import Config from '../../utils/config';
import store from '../../store';
// internal
import { valid, invalid } from '../../utils/validators/resolutions';
import stripDelimiter from '../../utils/strip-delimiter-from-currency';

import { region, brand } from '../../localizations/current-locale';
import { dynamicBrand } from '../../utils/brand';

// eslint-disable-next-line import/no-dynamic-require
let messages;
import(`../../utils/text-resources/${dynamicBrand()}/${region}/messages`).then((m) => {
  messages = m.default;
});

const validateMonthlySalaryAfterTax = (input = '', ...props) => {
  const cleanedInput = stripDelimiter(input);
  const config = Config.getValues();
  const state = store.getState();
  const maxSalary = Math.round(parseInt(stripDelimiter(
    state?.form?.application?.values?.applicant?.annualIncomeBeforeTax), 10) / 12);

  if (!/\S+/.test(cleanedInput || '')) {
    return (props[2] === 'applicant.monthlyIncomeAfterTax'
      ? invalid(messages.monthlySalaryAfterTaxRequired)
      : invalid(messages.coApplicantMonthlySalaryRequired));
  }

  if (!/^(-?\d+)$/.test(cleanedInput)) {
    return (props[2] === 'applicant.monthlyIncomeAfterTax'
      ? invalid(messages.monthlySalaryOnlynumbers)
      : invalid(messages.coApplicantMonthlySalaryOnlynumbers));
  }

  const value = parseInt(cleanedInput, 10);

  const minSalary = config.RESTRICTIONS.MIN_SALARY;

  if (value < minSalary) {
    return (props[2] === 'applicant.monthlyIncomeAfterTax'
      ? invalid(messages.monthlySalaryTooLow, { minSalary, brand })
      : invalid(messages.coApplicantMonthlySalaryTooLow, { minSalary, brand }));
  }

  if (value > maxSalary) {
    return (props[2] === 'applicant.monthlyIncomeAfterTax'
      ? invalid(messages.monthlySalaryTooHigh, { maxSalary, brand })
      : invalid(messages.coApplicantMonthlySalaryTooHgih, { maxSalary, brand }));
  }

  return valid();
};

export default validateMonthlySalaryAfterTax;
