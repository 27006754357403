export default {
  ctaButtonBackground: 'var(--digi-blue-400)',
  buttonBorderRadius: '2.75rem',
  buttonBorderRadiusLead: '0.25rem',
  buttonPadding: '1.5rem',
  marginTop: '1rem',
  boxShadow: '0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24)',
  buttonWidth: '100%',
  disabledBg: 'var(--sg-grey-300)',
  disabledColor: 'var(--sg-grey-500)',
  breakBorder: '1px solid var(--sg-grey-200)',
  stepContainerBackground: 'var(--digi-blue-200)',
  stepContainerBackgroundAfter: ' var(--digi-blue-400)',
  buttonTextColor: 'var(--white)',
};
