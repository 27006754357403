import stripDelimiter from '../../utils/strip-delimiter-from-currency';
import { submitApplicant, submitCoApplicant } from './applicant';

const calculateNewLoan = (
  application,
) => {
  const totalAmount = parseInt(stripDelimiter(application.totalAmount), 10);
  const consolidationAmount = parseInt(stripDelimiter(application.consolidationAmount), 10);
  let newLoanAmount;
  const onlyNewLoan = (!application.merge);

  if ((consolidationAmount === totalAmount) && application.merge) {
    newLoanAmount = 0;
  } else if (onlyNewLoan) {
    newLoanAmount = totalAmount;
  } else {
    newLoanAmount = totalAmount - consolidationAmount;
  }

  return newLoanAmount;
};

const definePurpose = (
  application,
) => {
  const mergeIsNotFalse = (application.merge || application.merge === null);

  const totalIsEqualToConsolidation = (application.consolidationAmount === application.totalAmount);

  const mergeWithoutPurpose = (application.merge && application.purpose === undefined);

  if ((mergeIsNotFalse && totalIsEqualToConsolidation) || mergeWithoutPurpose) {
    return 'consolidateLoans';
  }
  return application.purpose;
};

const submit = (application, visibleFields) => ({
  inputSource: application.source,
  totalAmount: String(parseInt(stripDelimiter(application.totalAmount), 10)),
  newLoan: calculateNewLoan(application),
  repaymentTime: String(parseInt(application.repaymentTime, 10) * 12),
  purpose: definePurpose(application),
  acceptNewsletter: application.acceptNewsletter,
  applicant: application.applicant
    ? submitApplicant(application.applicant, visibleFields) : null,
  coApplicant: application.coApplicant
    ? submitCoApplicant(application.coApplicant, visibleFields) : null,
  creditCheckConsent: application.creditCheckConsent || null,
});

export default submit;

