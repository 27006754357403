const LINK_URLS = {
  WEBSITE: 'https://digifinans.no',
  CUSTOMERSUPPORTLINK: '#',
  EFFECTIVEINTERESTRATE: '#',
  AMORTIZATION: '#',
  USER_AGREEMENT: '#',
  DATA_POLICY_LINK: '#',
  FORM_DATA_CONSENT_LINK: '#',
};

export default LINK_URLS;
