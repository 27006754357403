// external
import { region } from '../localizations/current-locale';
import { dynamicBrand } from '../utils/brand';

// internal
import { valid, invalid } from '../utils/validators/resolutions';
import stripDelimiter from '../utils/strip-delimiter-from-currency';
import Config from '../utils/config';

let messages;

import(`../utils/text-resources/${dynamicBrand()}/${region}/messages`).then((m) => {
  messages = m.default;
});

const validateOtherIncomeAfterTax = (input = '', ...props) => {
  const cleanedInput = stripDelimiter(input);
  const config = Config.getValues();

  if (!/\S+/.test(cleanedInput || '')) {
    return (props[2] === 'applicant.otherMonthlyIncomeAfterTax'
      ? invalid(messages.otherIncomeAfterTaxRequired)
      : invalid(messages.coApplicantOtherIncomeAfterTaxRequired));
  }

  if (!/^(-?\d+)$/.test(cleanedInput)) {
    return (props[2] === 'applicant.otherMonthlyIncomeAfterTax'
      ? invalid(messages.otherIncomeOnlyNumbers)
      : invalid(messages.coApplicantOtherIncomeOnlyNumbers));
  }

  const value = parseInt(cleanedInput, 10);

  const maxIncome = config.RESTRICTIONS.MAX_OTHER_INCOME;
  if (value > maxIncome) {
    return (props[2] === 'applicant.monthlyIncomeAfterTax'
      ? invalid(messages.otherIncomeTooHigh, { maxIncome, dynamicBrand })
      : invalid(messages.coApplicantOtherIncomeTooHgih, { maxIncome, dynamicBrand }));
  }

  const otherMonthlyIncomeBeforeTax = parseInt(
    stripDelimiter(props[0].applicant.otherMonthlyIncomeBeforeTax), 10);
  const coApplicantIncomeBeforeTax = parseInt(
    stripDelimiter(props[0].coApplicant.otherMonthlyIncomeBeforeTax), 10);

  if ((otherMonthlyIncomeBeforeTax < value && props[2] === 'applicant.otherMonthlyIncomeAfterTax')
  || (coApplicantIncomeBeforeTax < value && props[2] === 'coApplicant.otherMonthlyIncomeAfterTax')) {
    return invalid(messages.monthlySalaryAfterTaxInvalid);
  }

  return valid();
};

export default validateOtherIncomeAfterTax;
