import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import styled, { css } from 'styled-components';
import messageDescriptor from '../../../../../js/schemas/message-descriptor';
import intlShape from '../../../../../js/schemas/intl';
import tooltipSchema from '../../../../../js/schemas/tooltip';
import tooltipToObject from '../../../../../js/helpers/tooltipToObject';

import Tooltips from '../../../../utils/tooltips';

const Wrapper = styled.div`
  && {
    margin: ${(props) => props.margin || '0 0 1rem 0'};
    display: flex;
    position: relative;
    flex-direction: column;
    font-family: var(--font-family);
    font-size : 1rem;
    color: var(--digi-blue-700)!important;
    font-weight: 500;
  }
`;

const LabelWrapper = styled.div`
  && {
    display: flex;
    align-items: center;
    grid-gap: 0.5rem;
    padding: 0 0 0.5rem;
    font-weight: 600;
  }
`;

const Label = styled.label`
  && {
    margin: 0;
    padding: 0;
    position: relative;
    z-index: 1;
  }
`;

const Input = styled.div`
  && {
    align-items: center;
    background: var(--white);
    border-radius: 4px;
    border: 1px solid var(--digi-blue-700);
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    cursor: text;
    display: flex;
    height: 100%;
    justify-content: space-between;
    overflow: hidden;
    color: var(--digi-blue-700);

    ${(props) => props.valid && props.validated && css`
      border: 2px solid var(--rahalaitos-green-400);
    `}

    ${(props) => (props.validated && props.valid === false) && css`
      border: 2px solid var(--digi-red-400);
    `}

    ${(props) => props.valid === null && css`
      border-color: var(--digi-blue-700);
    `}
  }
`;

const InputSelect = styled.select`
  && {
    background: var(--white);
    border: 0;
    -ms-flex: 1 1;
    flex: 1 1;
    height: 100%;
    outline: none;
    overflow: hidden;
    padding: 0.5rem 0.625rem;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: var(--digi-blue-700);
    width: 100%;
    background-size: 1rem;
    background-position: 98%;
    background-repeat: no-repeat;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url(https://no-digifinans-production-cms-uploadbucket-14u89bxmjgysi.s3.amazonaws.com/uploads/2023/04/Digifinans_arrow_down.svg);

    ${(props) => props.valid === null && css`
      color: var(--SG-grey-500);
    `}
  }
`;

const Select = ({
  id,
  name,
  compact,
  labelInside,
  label,
  multiple,
  validated,
  valid,
  className,
  options,
  tooltip,
  sorted,
  tooltipLink,
  intl: { formatMessage },
  override,
  margin,
  placeholder,
  ...rest
}) => {
  const selectId = id || `select--${name || ''}--${label.replace(/\s/, '-')}`;

  const getLabel = (option) => {
    if (typeof option.label === 'string') return option.label;

    return formatMessage(option.label);
  };

  const getKey = (option) => {
    if (typeof option.label === 'string') return option.label;

    return option.label.id;
  };

  const selectOption = (option) => (
    <option
      key={option.value}
      value={option.value}
      disabled={option.disabled}
    >
      {getLabel(option)}
    </option>
  );

  switch (sorted) {
    case 'A-Z':
      options.sort((a, b) => {
        if (formatMessage(a.label) < formatMessage(b.label)) { return -1; }
        if (formatMessage(a.label) > formatMessage(b.label)) { return 1; }
        return 0;
      });
      // add placeholder back on top
      // eslint-disable-next-line no-nested-ternary
      options.sort((x, y) => (x.value === '' ? -1 : y.value === '' ? 1 : 0));
      break;
    default:
  }

  const selectOptions = options.map((option) => (
    option.subOptions
      ? (
        <optgroup
          key={getKey(option)}
          label={getLabel(option)}
        >
          {option.subOptions.map((subOption) => selectOption(subOption))}
        </optgroup>
      )
      : selectOption(option)
  ));

  const tooltipObject = tooltipToObject(tooltip);
  if (typeof tooltip === 'object') { tooltipObject.text = formatMessage(tooltip.text); }

  return (
    <Wrapper
      margin={margin}
    >
      {label && label.id && (
      <LabelWrapper>
        <Label
          htmlFor={selectId}
        >
          {formatMessage(label)}
        </Label>
        { tooltipObject.text
          && (
            <Tooltips tooltipObject={tooltipObject} />
          )}
      </LabelWrapper>
      )}

      <Input
        validated={validated}
        valid={valid}
        placeholder={placeholder}
      >
        <InputSelect
          placeholder={placeholder}
          valid={valid}
          validated={validated}
          id={selectId}
          name={name}
          multiple={multiple}
          {...rest}
        >
          {selectOptions}
        </InputSelect>
      </Input>

    </Wrapper>
  );
};

const optionSchema = PropTypes.shape({
  label: PropTypes.oneOfType([
    PropTypes.string,
    messageDescriptor,
  ]).isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  disabled: PropTypes.bool,
});

Select.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  margin: PropTypes.string,
  compact: PropTypes.bool,
  labelInside: PropTypes.bool,
  label: PropTypes.string,
  multiple: PropTypes.bool,
  sorted: PropTypes.string,
  validated: PropTypes.bool,
  valid: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([
      optionSchema,
      PropTypes.shape({
        subOptions: PropTypes.arrayOf(optionSchema),
      }),
    ]),
  ),
  className: PropTypes.string,
  tooltip: tooltipSchema,
  tooltipLink: PropTypes.string,
  intl: intlShape.isRequired,
  placeholder: PropTypes.shape(),
  override: PropTypes.shape({
    input: PropTypes.shape({
      labelClassName: PropTypes.string,
    }),
  }),
};

Select.defaultProps = {
  id: null,
  name: null,
  compact: false,
  labelInside: false,
  label: '',
  margin: '',
  sorted: '',
  multiple: false,
  validated: false,
  valid: null,
  options: [],
  className: '',
  tooltip: '',
  tooltipLink: '',
  placeholder: {},
  override: {
    input: {},
  },
};

export default injectIntl(Select);
